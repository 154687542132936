import { PostMessageBet9ja } from 'src/lib/postMessageValidator'
import { fakeOTPs } from '../mockCredentials'

export const yajuegoColombiaPM: PostMessageBet9ja = {
  otp: fakeOTPs.nofail_a,
  source: 'new-desktop',
  selections: [
    {
      selection: '8888924$B_12_1',
      event: 'Denver Nuggets - Golden State Warriors',
      market: '2 posibilidades',
      sign: 'Denver Nuggets',
      odd: '1.56',
      tournament_id: 83058,
      tournament_name: 'NBA',
    },
    {
      selection: '8917935$S_1X2_1',
      event: 'Crystal Palace FC - AFC Bournemouth',
      market: '1X2',
      sign: 'Crystal Palace FC',
      odd: '2.1',
      tournament_id: 90315,
      tournament_name: 'Premier League',
    },
    {
      selection: '8917945$S_DC_12',
      event: 'Brighton & Hove Albion FC - Brentford',
      market: 'Doble Oportunidad',
      sign: '12',
      odd: '1.24',
      tournament_id: 90315,
      tournament_name: 'Premier League',
    },
    {
      selection: '8917935$S_1X2GGNG_1GG',
      event: 'Crystal Palace FC - AFC Bournemouth',
      market: '1X2 y Ambos Equipos Marcarán',
      sign: 'Local/Sí',
      odd: '5.15',
      tournament_id: 90315,
      tournament_name: 'Premier League',
    },
    {
      selection: '8917951$S_OU@2.5_O',
      event: 'Fulham FC - Nottingham Forest',
      market: 'Totales',
      sign: 'Más (2.5)',
      odd: '2.1',
      tournament_id: 90315,
      tournament_name: 'Premier League',
    },
    {
      selection: '8917937$S_1X2ORGGNG_1GG',
      event: 'Everton FC - Newcastle United FC',
      market: '1X2 o Ambos Equipos Marcarán',
      sign: 'Local o GG',
      odd: '1.32',
      tournament_id: 90315,
      tournament_name: 'Premier League',
    },
  ],
  stake: 13400,
}
