import { PostMessageSelection } from 'src/lib/postMessageValidator'
import { SimBet, Source } from './types'
import { SportsCode, getSport } from './sportMap'
import { findMarketFromID } from './marketUtils'
import { Outcome } from './soccer/markets'

const getSymbolToUse = () => {
  return '_'
}

const getSpread = (
  sign?: string,
  mkt?: string,
  market_id?: number,
  sport_id?: number,
) => {
  const sign_num = sign?.replace(/[^\d.|^.]*/g, '')
  const outcome_num = mkt?.replace(/[^\d.|^.]*/g, '')
  let market = ''

  if (market_id && sport_id) {
    const m = findMarketFromID(market_id, sport_id)
    market = m.slice(-5).replace(/[^\d.|^.]*/g, '')
  }

  if (market) return market
  if (sign_num) return sign_num
  if (outcome_num) return outcome_num
  return ''
}

export const yajuegoColombiaAdapter = (
  pm: PostMessageSelection,
  src: Source,
  permissions: { [key: string]: boolean },
): SimBet => {
  const unsupportedSelection = {
    b9jBet: pm.selection_id,
    sport: pm.sport_id,
    supported: false,
    event: pm.event_name,
  } as SimBet

  // get the symbol to split on
  const symbol = getSymbolToUse()
  const [head, MKT, outcome] = pm.selection_id.split(symbol)
  let newOutcome = outcome
  if (MKT.includes('OUHOME')) {
    newOutcome = `${outcome}H`
  }
  if (MKT.includes('OUAWAY')) {
    newOutcome = `${outcome}A`
  }
  if (MKT.includes('MARGINHOME')) {
    newOutcome = `Home${outcome}`
  }
  if (MKT.includes('MARGINAWAY')) {
    newOutcome = `Away${outcome}`
  }
  if (MKT.includes('1X2ORGGNG')) {
    newOutcome = `${outcome}_or`
  }
  if (MKT.includes('1X2OROU')) {
    newOutcome = `${outcome}_or`
  }

  const [, sport] = head.split('$')

  // find which sport map to use
  const sportMap = getSport(src, sport as SportsCode)
  if (!sportMap) {
    return unsupportedSelection
  }

  const { sportId, marketMap, outcomeMap, findSimMarketId } = sportMap

  if (sportId === 2 && !permissions?.basketball) {
    return unsupportedSelection
  }

  const simulateMarket = marketMap.get(MKT) // find if there is a matching market in the map
  const supported = marketMap.has(MKT) // find if market is supported
  const simMarketID = findSimMarketId(simulateMarket, sportId) || 0
  const spread = getSpread(pm.sign_name, MKT, simMarketID, sportId)
  pm.odd = typeof pm.odd === 'string' ? parseFloat(pm.odd) : pm.odd // cast odd and replace
  const simOutcome = simulateMarket
    ? outcomeMap(MKT, newOutcome)
    : ('' as Outcome) // find the outcome
  if (simOutcome === ('' as Outcome)) return unsupportedSelection

  // return a valid simulate object
  return {
    b9jBet: pm.selection_id,
    matchID: pm.event_id,
    sport: pm.sport_id,
    odd: pm.odd,
    event: pm.event_name,
    market: pm.market_name,
    simMarketID,
    outcome: pm.sign_name,
    simOutcome,
    supported,
    blocking: false,
    spread,
    tournament_id: pm.tournament_id?.toString(),
    tournament_name: pm.tournament_name,
  }
}
